<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Relatório de uso por menu
    </hero-bar>
    <section class="section is-main-section">
      <MenuReportFilters v-on:applyFilters="fetchAll" />

      <card-component title="Divisão de atendimento por opção de menu (Clique nas opções para mais detalhes de tickets)"
        icon="finance">
        <div v-if="defaultChart.attendantChart" class="chart-area">
          <pie-chart ref="bigChart" style="height: 100%;" chart-id="big-line-chart"
            :chart-data="defaultChart.attendantChart" :extra-options="defaultChart.extraOptions"
            @on-receive="() => alert('id')">
          </pie-chart>
        </div>
      </card-component>

      <card-component :title="getTicketsTableTitle()" class="has-table has-mobile-sort-spaced">
        <b-loading :is-full-page="false" v-model="fetchingMenuReport" :can-cancel="true"></b-loading>
        <b-table :data="getMenuReportTickets" :bordered="true" :striped="true" paginated :mobile-cards="true"
          v-if="this.selectedOption" sort-icon="arrow-up" sort-icon-size="is-small" default-sort="id"
          aria-next-label="Próxima página" aria-previous-label="Página anterior" aria-page-label="Página"
          aria-current-label="Página atual" scrollable :show-detail-icon="true" detailed>
          <b-table-column sortable field="id" label="ID" v-slot="props" searchable>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="client_name" label="Nome no whats" v-slot="props" searchable>
            {{ props.row.client_name }}
          </b-table-column>

          <b-table-column field="contact_name" label="Contato" v-slot="props" searchable>
            {{ props.row.contact_id && props.row.contact_name ? `${props.row.contact_id} -
                        ${props.row.contact_name}` : ''
            }}
          </b-table-column>

          <b-table-column field="department_name" label="Departamento" v-slot="props" searchable>
            {{ props.row.department_id && props.row.department_name ? `${props.row.department_id} -
                        ${props.row.department_name}` : ''
            }}
          </b-table-column>

          <b-table-column field="client_phone" label="Telefone" v-slot="props" searchable>
            {{ props.row.client_phone }}
          </b-table-column>

          <b-table-column sortable field="client_phone" label="Tipo" v-slot="props">
            <IsGroupTag :is_group="!!props.row.is_group" />
          </b-table-column>

          <b-table-column field="attendant_name" label="Atendente" v-slot="props" searchable>
            {{ props.row.attendant_username && props.row.attendant_name ? `${props.row.attendant_username} -
                        ${props.row.attendant_name}` : ''
            }}
          </b-table-column>

          <b-table-column sortable field="is_open" label="Status" v-slot="props">
            {{ props.row.is_open ? "Aberto" : "Fechado" }}
          </b-table-column>

          <b-table-column field="created_at" label="Data de abertura" v-slot="props">
            {{ formatDateTime(props.row.created_at) }}
          </b-table-column>

          <b-table-column field="actions" label="Mensagens" v-slot="props">
            <MessagesTicketButton :ticket="props.row" />
          </b-table-column>

          <b-table-column field="actions" label="Transferir" v-slot="props" v-if="getIsSupervisor">
            <TransferTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <b-table-column field="actions" label="Fechar" v-slot="props" v-if="getIsSupervisor">
            <CloseTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ getMenuReportTickets.length }}
            </div>
          </template>

          <template #detail="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p v-if="props.row.ticket_reason">
                    Motivo do fechamento: <strong>{{ props.row.ticket_reason.id }} - {{ props.row.ticket_reason.title
                    }}</strong>
                    <br />
                  </p>
                  <p v-if="props.row.ticket_reason_explanation">
                    Explicação do atendente: <br />
                    <strong>{{ props.row.ticket_reason_explanation }}</strong>
                  </p>
                </div>
              </div>
            </article>
          </template>
        </b-table>
        <b-notification :closable="false" type="is-warning" v-else>
          Nenhuma opção de menu selecionada, por favor selecione uma opção de menu do gráfico acima para apresentar os
          tickets correspondentes.
        </b-notification>
      </card-component>
    </section>

    <ModalTicketDetails v-if="isModalTicketDetailsOpen" :ticket="selectedTicket" :showMessagesOption="true"
      v-on:closeModal="closeModal" :isComponentModalActive="isModalTicketDetailsOpen" />
  </div>
</template>

<script>
// @ is an alias to /src
import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import PieChart from '@/components/Charts/PieChart'
import IsGroupTag from '@/components/commons/IsGroupTag.vue'
// import ClientsTableSample from '@/components/ClientsTableSample'
import { mapActions, mapGetters } from 'vuex'
import ModalTicketDetails from '@/components/modals/ModalTicketDetails'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mixin from '../utils/mixins'
import CloseTicketButton from '@/components/tickets/CloseTicketButton'
import TransferTicketButton from '@/components/tickets/TransferTicketButton'
import MessagesTicketButton from '@/components/tickets/MessagesTicketButton'
import MenuReportFilters from '@/components/menuReports/MenuReportFilters'

export default {
  name: 'MenuReports',
  components: {
    PieChart,
    CardComponent,
    HeroBar,
    TitleBar,
    ModalTicketDetails,
    IsGroupTag,
    CloseTicketButton,
    TransferTicketButton,
    MessagesTicketButton,
    MenuReportFilters
  },
  mixins: [mixin],
  data () {
    return {
      isModalTicketDetailsOpen: false,
      selectedTicket: null,
      selectedOption: null,
      menuReport: {},
      currentFilters: {},
      defaultChart: {
        ticketsChartData: null,
        statusChartData: null,
        attendantChart: null,
        departmentChart: null,
        extraOptions: {
          ...chartConfig.chartOptionsMain,
          onClick: (point, event) => {
            const item = event[0]
            if (!item) return

            this.onChartClick({ index: item._index, label: item._view.label })
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'fetchingMenuReport',
      'getMenuReport',
      'getMenuReportTickets',
      'getIsSupervisor'
    ]),
    titleStack () {
      return ['Admin', 'Reports', 'Menu']
    }
  },
  methods: {
    ...mapActions([
      'fetchMenuReport',
      'fetchMenuReportTickets',
      'clearMenuReportTicketsData'
    ]),
    getTicketsTableTitle () {
      if (!this.selectedOption) return 'Selecione uma opção de menu para exibir os tickets correspondentes'
      return `Tickets correspondentes à opção (${this.selectedOption.option_id}) - ${this.selectedOption.option_description}`
    },
    async fetchAll (data) {
      this.currentFilters = data
      await this.fetchMenuReport(data)

      this.fillChartData()
    },
    async onChartClick (item) {
      this.selectedOption = this.getMenuReport[item.index]
      this.fetchMenuReportTickets({ ...this.currentFilters, menu_option_id: this.selectedOption.option_id })
    },
    fillChartData () {
      this.menuReport = []
      this.selectedOption = null
      this.clearMenuReportTicketsData()
      this.getMenuReport.map(tkt => {
        this.menuReport[`(${tkt.option_id}) - ${tkt.option_description}`] = tkt.tickets_count
      })

      this.defaultChart.attendantChart = {
        datasets: [
          {
            backgroundColor: 'rgb(255, 99, 132)',
            hoverOffset: 4,
            data: [...Object.values(this.menuReport)]
          }
        ],
        labels: [...Object.keys(this.menuReport)]
      }
    }
  }
}
</script>
